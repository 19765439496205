<template>
   <ObiText ref="modal" tabindex="-1" v-bind="attrs" v-on="$listeners" class="obi-modal modal fade">
      <ObiText class="modal-dialog" :class="classListDialog">
         <ObiText class="modal-content">
            <ObiText class="modal-header" v-if="title || subtitle || $slots.header">
               <slot name="header">
                  <ObiTitle class="modal-title" :title="title" :subtitle="subtitle" subtitle-class="fw-light" />
               </slot>
               <ObiButton close @click="hide" />
            </ObiText>

            <ObiText class="modal-body">
               <slot />
            </ObiText>

            <ObiText class="modal-footer" v-if="$slots.actions">
               <slot name="actions" />
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { Modal } from 'bootstrap';
import { keys, omit } from 'lodash';

export default {
   name: 'ObiModal',
   i18n: {
      messages: {
         tr: {
            actions: { cancel: 'Vazgeç' },
         },
         en: {
            actions: { cancel: 'Cancel' },
         },
      },
   },
   props: {
      title: {
         Type: String,
         default: null,
      },
      height: {
         Type: Number,
         default: 180,
      },
      subtitle: {
         Type: String,
         default: null,
      },
      sm: {
         Type: Boolean,
         default: null,
      },
      lg: {
         Type: Boolean,
         default: null,
      },
      xl: {
         Type: Boolean,
         default: null,
      },
      hideCancel: {
         Type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      classListDialog() {
         const classList = [];

         if (this.sm !== null) {
            classList.push('modal-sm');
         }

         if (this.lg !== null) {
            classList.push('modal-lg');
         }

         if (this.xl !== null) {
            classList.push('modal-xl');
         }

         return classList;
      },
   },
   data() {
      return {
         isOpened: false,
         modalInstance: null,
      };
   },
   mounted() {
      this.initModal();
   },
   methods: {
      show() {
         this.modalInstance.show();
      },
      hide() {
         this.modalInstance.hide();
      },
      initModal() {
         const elmModal = this.$refs.modal.$el;
         if (!elmModal) return;

         this.modalInstance = new Modal(elmModal, { backdrop: 'static' });
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-modal {
   .modal-content {
      border-color: #eaeaea;
      max-height: 700px;
   }

   .modal-header {
      padding: 10px 30px;
      border-bottom-color: #eaeaea;
   }

   .modal-body {
      padding: 30px;
      overflow-x: hidden;
      overflow-y: auto;
   }

   .modal-footer {
      display: grid;
      padding: 1rem 7rem;
      align-items: center;
      justify-content: center;
      grid-auto-flow: column;
      border-top-color: #eaeaea;
      grid-auto-columns: minmax(120px, auto);
   }
}
</style>
